<template>
  <AuthenticationForm id="view-recover-password">
    <div v-if="complete" class="py-4 px-4 sm:px-8">
      <h3 class="text-base font-semibold mb-6 text-center">
        Recovery requested
      </h3>
      <router-link class="text-tozny no-underline text-tiny" to="login"
        >Back to Sign In</router-link
      >
    </div>
    <div v-else class="py-4 px-4 sm:px-8">
      <h3 class="text-base font-semibold mb-6 text-center">
        Recover Your Identity
      </h3>
      <ToznyForm
        :error="error"
        :loading="loading"
        :onSubmit="btnRecover"
        class="begin-recovery"
      >
        <ToznyInput
          v-model="username"
          id="username"
          name="username"
          class="mb-6 md:text-lil"
          label="Username"
          float-label
        />
        <ToznyButton
          id="login-button"
          class="login-button w-full py-3 rounded-lg text-lil"
          buttonText="Recover"
          :loading="loading"
        />
      </ToznyForm>
      <router-link class="text-tozny no-underline text-tiny" to="login"
        >Back to Sign In</router-link
      >
    </div>
    <p class="text-lil text-gray-dark italic mx-8">Note: If your identity is from a federated source such as LDAP you must contact your administrator to reset your password.</p>
  </AuthenticationForm>
</template>

<script>
import { mapGetters } from 'vuex'

import AuthenticationForm from '@/Common/AuthenticationFrom/AuthenticationForm'
import ToznyForm from '@/Common/ToznyForm'
import ToznyButton from '@/Common/ToznyButton'
import ToznyInput from '@/Common/ToznyInput'
export default {
  name: 'RecoverIdentity',
  components: {
    AuthenticationForm,
    ToznyForm,
    ToznyButton,
    ToznyInput,
  },
  data: function() {
    return {
      username: '',
      error: '',
      loading: false,
      complete: false,
      resolve: false,
    }
  },
  computed: {
    ...mapGetters(['realm']),
  },
  methods: {
    async btnRecover() {
      this.error = ''
      this.loading = true
      const username = this.username.trim()
      if (username === '') {
        this.error = 'Please enter a valid username'
        this.loading = false
        return
      }
      try {
        await this.realm.initiateRecovery(username)
      } catch (e) {
        this.error = `Error requesting recovery: ${e.message}`
        this.loading = false
        return
      }
      this.loading = false
      this.complete = true
    },
  },
}
</script>
